import { pathOr } from 'ramda/es'
import { all, fork, takeEvery } from 'redux-saga/effects'

import { WatchSaga, getPageSaga, watchSaga, watchSagaFulfilled, watchSagaRejected } from '../../../helpers/customSaga'
import * as actions from '../../../redux/actions'
import * as API from '../api'
import actionTypes, {
  QUESTIONS_BANK,
  QUESTIONS_BANK_ADD_TEACHERS,
  QUESTIONS_BANK_ALL,
  QUESTIONS_BANK_CREATE,
  QUESTIONS_BANK_DELETE,
  QUESTIONS_BANK_LIST,
  QUESTIONS_BANK_REMOVE_TEACHERS,
} from '../constants/actionTypes'

function watchQuestionsBank() {
  return watchSaga({
    action: actionTypes[QUESTIONS_BANK],
    api: API.questionsBankGetById,
  })
}

function watchQuestionsBankRejected() {
  return watchSagaRejected({
    action: actionTypes[QUESTIONS_BANK],
    message: true,
  })
}

function watchQuestionsBankList() {
  return watchSaga({
    action: actionTypes[QUESTIONS_BANK_LIST],
    api: API.questionsBankList,
  })
}

function watchQuestionsBankListRejected() {
  return watchSagaRejected({
    action: actionTypes[QUESTIONS_BANK_LIST],
    message: true,
  })
}

const questionBankAllSaga = new WatchSaga({ actionType: actionTypes[QUESTIONS_BANK_ALL], api: API.questionsBankAll })

const questionBankCreateSaga = new WatchSaga({
  actionType: actionTypes[QUESTIONS_BANK_CREATE],
  api: API.questionsBankCreate,
})

const questionBankDelete = new WatchSaga({
  actionType: actionTypes[QUESTIONS_BANK_DELETE],
  api: API.questionsBankDelete,
})

function watchQuestionsBankAddTeachers() {
  return watchSaga({
    action: actionTypes[QUESTIONS_BANK_ADD_TEACHERS],
    api: API.questionsBankAddTeachers,
  })
}

function watchQuestionsBankRemoveTeachers() {
  return watchSaga({
    action: actionTypes[QUESTIONS_BANK_REMOVE_TEACHERS],
    api: API.questionsBankRemoveTeachers,
  })
}

export function* questionsBankSaga() {
  yield all([
    fork(watchQuestionsBank),
    fork(watchQuestionsBankRejected),
    fork(watchQuestionsBankList),
    fork(watchQuestionsBankListRejected),
    fork(questionBankAllSaga.watch),
    fork(questionBankAllSaga.watchRejected),
    fork(questionBankAllSaga.watchFulfilled),
    fork(questionBankCreateSaga.watch),
    fork(questionBankCreateSaga.watchFulfilled),
    fork(questionBankCreateSaga.watchRejected),
    fork(questionBankDelete.watch),
    fork(questionBankDelete.watchFulfilled),
    fork(questionBankDelete.watchRejected),
    fork(watchQuestionsBankAddTeachers),
    fork(watchQuestionsBankRemoveTeachers),
  ])
}
